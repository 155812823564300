<template>
  <div>
    <a-button-group>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
      <a-button type="primary" icon="upload" size="small" :disabled="item.status !== 'failed'" @click="uploadFedex">
        上传 Fedex
      </a-button>
    </a-button-group>
  </div>
</template>

<script>
import { conDestroy, conUploadFedex } from "@/apis/data";

export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    destroy() {
      conDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
    uploadFedex() {
      conUploadFedex({ id: this.item.id }).then(() => {
        this.$message.success("上传成功");
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style scoped></style>
